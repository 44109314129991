// SPDX-FileCopyrightText: 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

/*! This Executable Form is subject to Mozilla Public License, v. 2.0.
 *! The Source Code Form is available at https://gitlab.com/ahayzen/stringer-builders.co.uk
 *! Copyright 2020 Andrew Hayzen <ahayzen@gmail.com> */

// We construct the addresses here on demand from the data
function anchorEventHandler(this: HTMLAnchorElement, event: MouseEvent) : void {
    // Determine the href from the anchor
    let href: string = "";
    if (this.dataset.bind === "link-ext-m") {
        href = "mailto:" + this.dataset.name
            + "@" + this.dataset.domain + "." + this.dataset.tld;
    } else if (this.dataset.bind === "link-ext-t") {
        href = "tel:+44" + this.dataset.area + this.dataset.local;
    } else {
        return;
    }

    // Perform the action
    if (event.type === "click") {
        event.preventDefault();
        window.location.href = href;
    } else if (event.type === "mouseenter" || event.type === "mousemove") {
        this.href = href;
    } else if (event.type === "mouseleave") {
        this.href = "";
    }
}

window.addEventListener("load", function () {
    // Find all anchors that are using datasets
    for (let anchor of document.querySelectorAll<HTMLAnchorElement>("a[data-bind^='link-ext-']")) {
        // Use the parent container to detect when the mouse is close
        if (anchor.parentNode && anchor.parentNode.parentNode) {
            const container: HTMLElement = anchor.parentNode.parentNode as HTMLElement;
            container.addEventListener("mouseenter", anchorEventHandler.bind(anchor));
            container.addEventListener("mousemove", anchorEventHandler.bind(anchor));
            container.addEventListener("mouseleave", anchorEventHandler.bind(anchor));
        }

        // Connect the click event (for mobile)
        anchor.addEventListener("click", anchorEventHandler.bind(anchor));
    }
});
